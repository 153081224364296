import React from 'react'
import {FormattedMessage} from 'react-intl'
import styled from 'styled-components'

const TitleSectionHolder = styled.section`
    background: #fff;
    margin: 20px 0 40px;
    padding: 12px 20px 16px;
    text-align: center;

    h2 {
        margin: 0 0 2px;
        padding: 0;
        line-height: 40px;
        font-size: 30px;
        color: #d2c970;
        font-weight: 300;
    }
`
const TitleSection = (props) => (
    <TitleSectionHolder className="title-section">
        <h2>
            <FormattedMessage id={props.section} />
        </h2>
    </TitleSectionHolder>
)

export default TitleSection