import React from 'react'
import styled from 'styled-components'
import { Layout } from '../../components/common'
import { FormattedMessage } from 'react-intl'
import Header from '../../components/theme/Header'
import Footer from '../../components/theme/Footer'
import TitleSection from '../../components/common/TitleSection'

const HistoryText = styled.p`
    font-size: 16px;
    line-height: 24px;
    color: #616156;
`

const History7 = () => (
    <Layout>
        <React.Fragment>
            <Header />
            <div className="main-content-holder">
                <TitleSection section="history7Header" />
                <HistoryText>
                    <FormattedMessage id="history7Text" />
                </HistoryText>
            </div>
            <Footer />
        </React.Fragment>
    </Layout>
)

export default History7
